"use cleint";
import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import Link from "next/link";
import MyLink from "@/components/shared/myLink/MyLink";

const index = () => {
  return (
    <div id="FAQ" className=" container my-20">
      <p
        className="titleHeading"
        // data-aos="fade-up"
      >
        الاسئلة المتكررة
      </p>
      <p
        className=" text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10"
        // data-aos="fade-up"
      >
        هذه أكثر الأسئلة التي يسألها الطلاب - يمكنك فتح السؤال ومشاهدة الرد من
        الدعم الفني .
      </p>
      <Accordion
        type="single"
        collapsible
        className=" flex flex-col gap-5"
        defaultValue="item-1"
      >
        <AccordionItem
          value="item-1"
          className=" bg-[#323538] py-[15px] px-[20px] rounded-[20px]"
          // data-aos="fade-up"
        >
          <AccordionTrigger className=" text-20 text-white font-[500]">
            كيفية التواصل مع الدعم الفني للمنصة ؟{" "}
          </AccordionTrigger>
          <AccordionContent className=" text-16 text-textColor font-[500]">
            يمكنك التواصل مع الدعم الفني للمنصة من خلال : واتساب :-:-{" "}
            <MyLink
              href={"https://wa.me/+201080556105"}
              className="text-primaryColor"
              target="_blank"
            >
              أضغط الآن
            </MyLink>{" "}
            أو من خلال صفحة الفيسبوك :-{" "}
            <MyLink
              href={"https://www.facebook.com/profile.php?id=61561661176951"}
              className=" text-primaryColor"
              target="_blank"
            >
              تواصل معنا
            </MyLink>
          </AccordionContent>
        </AccordionItem>
        {/* <AccordionItem
          value="item-2"
          className=" bg-[#323538] py-[15px] px-[20px] rounded-[20px]"
          data-aos="fade-up"
        >
          <AccordionTrigger className=" text-20 text-white font-[500]">
            ما هي وسائل الدفع المتاحة
          </AccordionTrigger>
          <AccordionContent className=" text-16 text-textColor font-[500]">
            يمكنك شراء الحصص عن طريق فوري / أمان / اي محفظه إلكترونية / فيزا
          </AccordionContent>
        </AccordionItem> */}
      </Accordion>
    </div>
  );
};

export default index;
