"use client";
import MyLink from "@/components/shared/myLink/MyLink";
import Link from "next/link";
import React from "react";

const index = () => {
  return (
    <div className="container">
      <div className="bg-primaryColor h-[321px] 2xl:h-[489px] rounded-[36px] flex flex-col items-center justify-center gap-10 2xl:gap-5 my-20 ">
        <p className=" text-45 text-[#202225] font-[700]">مستعد للنجاح؟</p>
        <p className=" text-30 text-[#202225] font-[500] max-w-[900px] text-center">
          منصة Eduvalu هي منصة تهدف إلي تطوير العملية التعليمية لمساعدة الطالب
          في تحقق النجاح في جميع المواد والتفوق بها وذلك من خلال الخدمات التي
          نقدمها.
        </p>
        <MyLink
          href={"/signup"}
          className="text-16 text-[#202225] font-[600] bg-white shadow-[0px_4px_134px_0px_#FFF] rounded-[10px] py-3 px-20"
        >
          انشئ حساب الان
        </MyLink>
      </div>
    </div>
  );
};

export default index;
