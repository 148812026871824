"use client";
import React, { useEffect, useState } from "react";
import instructorImage from "@/public/images/instructorImage.svg";
import BookCard from "../../shared/BookCard";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Link from "next/link";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import USER, { SelectBooks } from "@/redux/User";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MyLink from "@/components/shared/myLink/MyLink";

const Index = ({ books, loading }: any) => {
  const dispatch = useAppDispatch();
  // const books = useAppSelector(SelectBooks);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await dispatch(
  //       USER.thunks.doGetBooks({
  //         search: "",
  //         subject_id: "",
  //         teacher_name: "",
  //         school_years: [],
  //         education_types: [],
  //       })
  //     );
  //     setLoading(false);
  //   };
  //   fetchData();
  // }, []);

  return (
    <div className="container my-20">
      <p className="titleHeading">أشهر الكتب مبيعًا في المنصة</p>
      <p className="text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10">
        يوجد لدينا متجر كتب يحتوي علي كتب لأفضل المدرسين علي نطاق الجمهورية .
      </p>
      {loading ? (
        <SkeletonTheme baseColor="#474A4D" highlightColor="#6b6e72">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 pb-10">
            {Array(3)
              .fill(0)
              .map((_, index) => (
                <div key={index} className="p-5 bg-[#323538] rounded-[15px]">
                  <Skeleton height={220} className="w-full" />
                  <Skeleton height={20} width="50%" className="my-3" />
                  <Skeleton height={20} width="70%" />
                  <Skeleton height={20} width="40%" className="mt-3" />
                  <Skeleton height={30} width="100%" className="mt-5" />
                </div>
              ))}
          </div>
        </SkeletonTheme>
      ) : books && books.length > 0 ? (
        <Carousel
          className=" "
          opts={{
            direction: "rtl",
          }}
        >
          <CarouselContent className="pb-10">
            {books.map((book: any, index: number) => (
              <CarouselItem
                key={book.id || index}
                className="md:basis-1/2 xl:basis-1/3"
              >
                <BookCard
                  id={book.id}
                  image={book.image}
                  years={book.school_years}
                  teacherName={book.teacher_name}
                  teacherImage={instructorImage}
                  subject={book.subject?.name}
                  price={book.price}
                  name={book.name}
                  description={book.description}
                  examNumber="+500"
                  starNumber={book.stars}
                  fileNumber={`+${book.pages}`}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="flex justify-center items-center gap-3">
            <CarouselPrevious className="bg-[#323538] border-[#323538] hover:bg-[#323538]" />
            <MyLink
              href="/books"
              className="bg-[#323538] text-16 text-white font-[400] py-[10px] px-[40px] rounded-[8px]"
            >
              عرض الكل
            </MyLink>
            <CarouselNext className="bg-[#323538] border-[#323538] hover:bg-[#323538]" />
          </div>
        </Carousel>
      ) : (
        <div className="text-center text-red-400 bg-[#474A4D] rounded-[5px] py-5 px-3">
          <p>لم يتم العثور على كتب.</p>
        </div>
      )}
    </div>
  );
};

export default Index;
