import React from "react";
import Image from "next/image";
import redCircle from "@/public/images/redCircle.svg";
import greenCircle from "@/public/images/greenCircle.svg";
import Link from "next/link";
import useCreateQueryString from "@/hooks/useCreateQueryString";
import defaultImage from "@/public/images/default_image.jpg";
import MyLink from "../myLink/MyLink";

const index = ({
  subjectId,
  subjectImage,
  subjectName,
  subjectDesc,
  subjectTeachers,
}: {
  subjectId: number;
  subjectImage: string;
  subjectName: string;
  subjectDesc?: string;
  subjectTeachers?: string;
}) => {
  const { createQueryString } = useCreateQueryString();
  return (
    <MyLink
      href={`/instructors?${createQueryString(
        "subject_id",
        String(subjectId)
      )}`}
      className="block w-full"
    >
      <div
        className=" flex items-center gap-3 p-5 bg-[#323538] rounded-[15px] w-full"
        data-aos="fade-up"
      >
        {/* <div className=" bg-[#1e73550a] rounded-[8px] p-5"> */}
        <Image
          src={subjectImage || defaultImage}
          alt="subjectsCardImage"
          width={89}
          height={96}
        />
        {/* </div> */}
        <div className=" flex flex-col justify-between  ">
          <p className=" text-20 text-white font-[500]">{subjectName}</p>
          {subjectDesc && (
            <p className=" text-16 text-textColor max-w-[300px]">
              {subjectDesc}
            </p>
          )}
          <div className=" flex items-center gap-2">
            {subjectTeachers ? (
              <Image src={greenCircle || defaultImage} alt="greenCircle" />
            ) : (
              <Image src={redCircle} alt="redCircle" />
            )}

            <p className=" text-14 text-white">
              ( {subjectTeachers} مدرس ) موجود
            </p>
          </div>
        </div>
      </div>
    </MyLink>
  );
};

export default index;
