import { usePathname, useSearchParams } from "next/navigation";
import { useCallback } from "react";

function useCreateQueryString() {
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  return { pathname, createQueryString };
}

export default useCreateQueryString;
