import React from "react";
import time from "@/public/images/time.svg";
import lecture from "@/public/images/lecture.svg";
import exam from "@/public/images/exam.svg";
import bookmark from "@/public/images/bookmark.svg";
import Image from "next/image";
import Link from "next/link";
import { checkInclude } from "@/lib/checkInclude/checkInclude";
import defaultImage from "@/public/images/default_image.jpg";
import MyLink from "../myLink/MyLink";

const CourseCard = ({
  course,
  myCourses,
  isFullExam = false,
}: {
  course: any;
  myCourses?: any;
  isFullExam?: boolean;
}) => {
  return (
    <>
      <div
        data-aos="fade-up"
        className="p-5 bg-[#323538] rounded-[15px] w-fit border border-[#56566B] flex flex-col gap-5 justify-between h-full"
      >
        <Image
          src={course?.image}
          alt={course?.name}
          className="w-[400px] h-[220px] object-cover rounded-[10px]"
          height={220}
          width={400}
        />
        <div className="flex items-center gap-3">
          <Image
            src={bookmark}
            alt="Bookmark Icon"
          />
          <p className="text-16 text-primaryColor font-[400]">
            {course.school_years?.map((year: any) => year?.name).join(", ")}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-start gap-3">
            {course?.teacher?.image && (
              <Image
                src={course.teacher.image}
                alt={course.teacher.name}
                width={64}
                height={64}
                className="rounded-[10px]"
              />
            )}
            <div>
              <p className="text-14 text-white font-[500]">
                {course?.teacher?.name}
              </p>
              <p className="text-12 text-textColor">
                {course?.teacher?.subject_name}
              </p>
            </div>
          </div>
          <p className="text-16 text-primaryColor font-[600] text-center py-[5px] px-4 rounded-full border border-primaryColor">
            {!course.free && course.price ? `${course?.price} جنية` : "مجاني"}
          </p>
        </div>
        <h4 className="text-white text-[20px] font-[600] line-clamp-2">
          {course?.name}
        </h4>
        {isFullExam ? (
          <div className="bg-[#474A4D] rounded-[10px] flex items-center justify-between py-3 px-3 md:px-3">
            <div className="flex flex-col items-center justify-center grow">
              <Image
                src={time}
                alt="Time Icon"
              />
              <p className="text-16 text-textColor font-[500]">
                {course?.duration} دقيقه
              </p>
            </div>
            <div className="flex flex-col items-center justify-center grow border-r border-[#6F7275]">
              <Image
                src={exam}
                alt="Exam Icon"
              />
              <p className="text-16 text-textColor font-[500]">
                {course?.number_of_questions} سؤال
              </p>
            </div>
          </div>
        ) : (
          <div className="bg-[#474A4D] rounded-[10px] flex items-center justify-between py-3 px-3 md:px-3">
            <div className="flex flex-col items-center justify-center grow">
              <Image
                src={time}
                alt="Time Icon"
              />
              <p className="text-16 text-textColor font-[500]">
                {course?.hours} ساعة
              </p>
            </div>
            <div className="flex flex-col items-center justify-center grow border-x border-[#6F7275]">
              <Image
                src={lecture}
                alt="Lecture Icon"
              />
              <p className="text-16 text-textColor font-[500]">
                {course?.lectures} محاضرة
              </p>
            </div>
            <div className="flex flex-col items-center justify-center grow">
              <Image
                src={exam}
                alt="Exam Icon"
              />
              <p className="text-16 text-textColor font-[500]">
                {course?.exams} امتحانات
              </p>
            </div>
          </div>
        )}
        {myCourses &&
        myCourses?.courses &&
        checkInclude(course.id, myCourses?.courses) ? (
          <MyLink
            href={`/${isFullExam ? "" : "viewCourse"}/${course?.id}`}
            className="text-center text-13 font-[500] text-[#202225] bg-primaryColor rounded-[7px] py-2 px-5"
          >
            الدخول لهذا الكورس
          </MyLink>
        ) : (
          <MyLink
            href={`/${isFullExam ? "fullExams" : "courses"}/${course?.id}`}
            className="text-center text-13 font-[500] text-[#202225] bg-primaryColor rounded-[7px] py-2 px-5"
          >
            عرض تفاصيل الكورس
          </MyLink>
        )}
      </div>
    </>
  );
};

export default CourseCard;
