"use client";
import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Link from "next/link";
import SubjectsCard from "../../shared/SubjectsCard";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import USER, { SelectSubjects } from "@/redux/User";
import MyLink from "@/components/shared/myLink/MyLink";

const Index = ({ subjects, loading }: any) => {
  const dispatch = useAppDispatch();
  // const subjects = useAppSelector(SelectSubjects);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchSubjects = async () => {
  //     await dispatch(
  //       USER.thunks.doGetSubjects({ search: "", school_years: [] })
  //     );
  //     setLoading(false);
  //   };
  //   fetchSubjects();
  // }, [dispatch]);

  return (
    <div id="subjects" className="container my-20">
      <p className="titleHeading" data-aos="fade-up">
        المواد الدراسية الموجودة
      </p>
      <p
        className="text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10"
        data-aos="fade-up"
      >
        تتيح المنصة جميع المواد الدراسية لطلاب الشعب العلمية والأدبية وطلاب
        مدارس العربي واللغات.
      </p>
      {loading ? (
        <SkeletonTheme baseColor="#474A4D" highlightColor="#6b6e72">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 pb-10">
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className="p-5 bg-[#323538] rounded-[15px] flex gap-5"
                >
                  <Skeleton height={96} width={89} />
                  <div className="h-full w-full">
                    <Skeleton height={20} width="70%" className="my-3" />
                    <Skeleton height={20} width="90%" />
                    <Skeleton height={20} width="50%" className="mt-3" />
                  </div>
                </div>
              ))}
          </div>
        </SkeletonTheme>
      ) : (
        <Carousel
          className=""
          opts={{
            direction: "rtl",
          }}
        >
          <CarouselContent className="pb-10">
            {subjects?.map((subject: any, index: number) => (
              <CarouselItem
                className="basis-full md:basis-1/2 xl:basis-1/4"
                key={index}
              >
                <SubjectsCard
                  subjectId={subject.id}
                  subjectImage={subject.image}
                  subjectName={subject.name}
                  subjectDesc={subject.description}
                  subjectTeachers={subject.teachers_count}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <div
            className="flex justify-center items-center gap-3"
            data-aos="fade-up"
          >
            <CarouselPrevious className="bg-[#323538] border-[#323538] hover:bg-[#323538]" />
            <MyLink
              href="/subjects"
              className="bg-[#323538] text-16 text-white font-[400] py-[10px] px-[40px] rounded-[8px]"
            >
              عرض الكل
            </MyLink>
            <CarouselNext className="bg-[#323538] border-[#323538] hover:bg-[#323538]" />
          </div>
        </Carousel>
      )}
    </div>
  );
};

export default Index;
